<template>
  <div class="columns is-multiline">
    <div class="column is-full-mobile">
      <div class="has-text-centered">
        <h3 class="surtitle">Εισερχόμενη κλήση:</h3>
        <h1 class="title">{{ telephone }}</h1>
        <div v-if="isLoading" class="has-text-centered">
          <vue-loading
            :size="{ width: '60px', height: '60px' }"
            type="spin"
            color="#22A684"
          />
        </div>
        <div v-if="isLoaded && !hasCustomer" class="has-text-centered">
          <h4 class="subtitle is-4">Δε βρεθηκε πελάτης με το νούμερο αυτό</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueLoading from 'vue-loading-template';
import request from '@/utils/request';

export default {
  components: {
    VueLoading,
  },
  data: () => ({
    isLoading: false,
    isLoaded: false,
    customer: null,
  }),
  computed: {
    telephone() {
      return this.$route.params.telephone;
    },
    hasCustomer() {
      return !!this.customer;
    },
  },
  async created() {
    if (this.telephone) {
      try {
        this.isLoading = true;
        this.isLoaded = false;
        const { data } = await request.get('/customers-search-by-phone', {
          params: {
            term: this.telephone,
          },
        });

        this.customer = data.customer;

        this.$router.push({
          name: 'customers.edit',
          params: {
            uuid: data.customer.uuid,
          },
        });

        console.log(data);
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
        this.isLoaded = true;
      }
    }
  },
};
</script>

<style scoped>
.title {
  font-size: 48px;
}
</style>
